<template>
  <div role="main" class="container">
    <article>
      <h1 class="mb-4">{{ $t("pages.profile.title") }}</h1>
      <card-data-component :tabs="tabs" :currentTabKey="tabKey">
        <template #data>
          <div class="profile">
            <article>
              <h5>{{ $t("pages.profile.tabs.personal_data.general") }}</h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.employee_code')"
                      :value="userData.employeeCode"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.name')"
                      :value="userData.name"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.first_family_name')"
                      :value="userData.firstFamilyName"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.second_family_name')"
                      :value="userData.secondFamilyName"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.full_name')"
                      :value="userData.fullName"
                    />
                  </div>
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.company_phone')"
                      :value="userData.phoneNo2"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.company_email')"
                      :value="userData.companyEmail"
                    />
                  </div>
                </div>
                <div class="row" v-if="canEditGeneral">
                  <div class="col text-right">
                    <vue-button
                      class="btn-primary"
                      :title="$t('components.actions.edit')"
                      @click="showGeneralEditForm"
                    ></vue-button>
                  </div>
                </div>
              </form>
            </article>

            <article>
              <h5>{{ $t("pages.profile.tabs.personal_data.contact_data") }}</h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.address')"
                      :value="userData.address"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.address2')"
                      :value="userData.address2"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.post_code')"
                      :value="userData.postCode"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.city')"
                      :value="userData.city"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.country_region_code')"
                      :value="userData.countryRegionCode"
                    />
                  </div>
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.private_phone')"
                      :value="userData.mobilePhoneNo"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.extension')"
                      :value="userData.extension"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.private_email')"
                      :value="userData.email"
                    />
                  </div>
                </div>
                <div class="row" v-if="canDirectionContact">
                  <div class="col text-right">
                    <vue-button
                      class="btn-primary"
                      :title="$t('components.actions.edit')"
                      @click="showDirectionContactForm"
                    ></vue-button>
                  </div>
                </div>
              </form>
            </article>

            <article>
              <h5>{{ $t("pages.profile.tabs.personal_data.personal") }}</h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.social_Security_no')"
                      :value="userData.socialSecurityNo"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.no_autorizacion_residente')"
                      :value="userData.noAutorizacionResidente"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.cass_expiration_date')"
                      :value="userData.cassExpirationDate"
                    >
                      <template #default="props">
                        <div class="description">
                          <formatted-date-component :val="props.value"></formatted-date-component>
                        </div>
                      </template>
                    </suc-text-form-show-field>

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.dni_number')"
                      :value="userData.dniNumber"
                    />
                  </div>
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.dni_expiration_data')"
                      :value="userData.dniExpirationDate"
                    >
                      <template #default="props">
                        <div class="description">
                          <formatted-date-component :val="props.value"></formatted-date-component>
                        </div>
                      </template>
                    </suc-text-form-show-field>

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.nationality_code')"
                      :value="userData.nationality"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.nationality_description')"
                      :value="userData.nationalityDescription"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.birth_day')"
                      :value="userData.birthDate"
                    >
                      <template #default="props">
                        <div class="description">
                          <formatted-date-component :val="props.value"></formatted-date-component>
                        </div>
                      </template>
                    </suc-text-form-show-field>
                  </div>
                </div>
                <div class="row" v-if="canEditPersonal">
                  <div class="col text-right">
                    <vue-button
                      class="btn-primary"
                      :title="$t('components.actions.edit')"
                      @click="showPersonalForm"
                    ></vue-button>
                  </div>
                </div>
              </form>
            </article>

            <article>
              <h5>{{ $t("pages.profile.tabs.personal_data.payments") }}</h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.iban')"
                      :value="userData.iban"
                    />
                  </div>
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.swift_code')"
                      :value="userData.swiftCode"
                    />
                  </div>
                </div>
                <div class="row" v-if="canEditPayment">
                  <div class="col text-right">
                    <vue-button
                      class="btn-primary"
                      :title="$t('components.actions.edit')"
                      @click="showPaymentForm"
                    ></vue-button>
                  </div>
                </div>
              </form>
            </article>
          </div>
        </template>
      </card-data-component>
    </article>

    <general-edit-modal />
    <direction-contact-modal />
    <personal-modal />
    <payment-modal />
  </div>
</template>

<script>
import CardDataComponent, { Tab } from "@/pages/components/card-data";
import api from "@/api/user";
import { mapActions, mapGetters } from "vuex";
import { VueButton } from "@/components/form";
import { FormattedDateComponent } from "@/components/formatted";
import { SucTextFormShowField } from "@/components/form";
import {
  GeneralEditModal,
  DirectionContactModal,
  PersonalModal,
  PaymentModal,
  SHOW_GENERAL_EDIT_FORM_EVENT,
  SHOW_PERSONAL_EDIT_FORM_EVENT,
  SHOW_DIRECTION_CONTACT_EDIT_FORM_EVENT,
  SHOW_PAYMENT_EDIT_FORM_EVENT,
  PROFILE_DATA_UPDATED_EVENT,
} from "./index";

import { WhoAmIMixin } from "@/components/mixins";

export default {
  mixins: [WhoAmIMixin],
  name: "ProfilePage",
  components: {
    VueButton,
    CardDataComponent,
    FormattedDateComponent,
    GeneralEditModal,
    DirectionContactModal,
    PersonalModal,
    PaymentModal,
    SucTextFormShowField,
  },
  layout: "MainLayout",
  middleware: ["auth", "notification"],
  data() {
    return {
      tabs: [new Tab({ name: this.$t("pages.profile.tabs.personal_data.title") })],
      isLoading: false,
      tabKey: "",
    };
  },
  computed: {
    ...mapGetters("auth", [
      "userData",
      "canEditGeneral",
      "canDirectionContact",
      "canEditPersonal",
      "canEditPayment",
    ]),
  },
  created() {
    EventBus.listen(PROFILE_DATA_UPDATED_EVENT, () => {
      this.init();
    });
  },
  mounted() {
    if (window.location.hash) {
      this.tabKey = window.location.hash.replace("#", "");
    } else {
      this.tabKey = "personal_data";
    }
    this.init();
  },
  beforeDestroy() {
    EventBus.off([PROFILE_DATA_UPDATED_EVENT]);
  },
  methods: {
    ...mapActions("auth", ["saveData"]),
    async init() {
      this.isLoading = true;

      const { data } = await api.whoAmI();
      this.saveData(this.$deepCopy(data));

      this.isLoading = false;
    },
    showGeneralEditForm() {
      this._fire(SHOW_GENERAL_EDIT_FORM_EVENT);
    },
    showDirectionContactForm() {
      this._fire(SHOW_DIRECTION_CONTACT_EDIT_FORM_EVENT);
    },
    showPersonalForm() {
      this._fire(SHOW_PERSONAL_EDIT_FORM_EVENT);
    },
    showPaymentForm() {
      this._fire(SHOW_PAYMENT_EDIT_FORM_EVENT);
    },
    _fire(event) {
      EventBus.fire(event, {
        data: this.$deepCopy(this.userData),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  & article {
    margin-top: 12px;

    & > h5 {
      border-bottom: 2px solid $color-primary;
      padding-bottom: 5px;
      margin-bottom: 12px;
    }
  }

  form label,
  .label-description {
    color: $color-gray;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19.6px;
    text-transform: uppercase;
  }

  form .description {
    color: $color-black;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
    height: 22.4px;
  }

  form .label-description {
    margin: 0;
  }

  form input {
    max-width: 300px;
  }
}
</style>
